import React, { useEffect, useState } from "react";
import PageTitle from "../../../components/PageTitle";
import { Card, Form, Table } from "react-bootstrap";
import { Pagination } from "antd";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

function PincodeCheck() {
  const [pincodes, setPincodes] = useState([]); // All pincodes from district
  const [statePincodes, setStatePincodes] = useState([]); // Post office details
  const [amt, setAmt] = useState(0);
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [states, setStates] = useState([]); // Dynamic states
  const [districts, setDistricts] = useState([]); // Dynamic districts
  const [isEditing, setIsEditing] = useState(false);
  const [currentPincodeId, setCurrentPincodeId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [selectedPincodes, setSelectedPincodes] = useState([]); // Track selected pincodes

  const TenantId = localStorage.getItem("TenantId");

  useEffect(() => {
    fetchStates(); // Fetch states on component mount
  }, []);
  const navigate = useNavigate();
  const fetchStates = async () => {
    try {
      const headers = new Headers();
      headers.append(
        "X-CSCAPI-KEY",
        "WkE5bHg2VmFIWGZTRFAySTIzeW9uRTRCOVJxR1NRbW5rcWRweHYxZg=="
      );

      const response = await fetch(
        "https://api.countrystatecity.in/v1/countries/IN/states",
        { headers }
      );

      if (!response.ok) {
        throw new Error(`Error fetching states: ${response.statusText}`);
      }

      const data = await response.json();
      const formattedStates = data.map((state) => ({
        value: state.iso2,
        label: state.name,
      }));

      setStates(formattedStates);
    } catch (error) {
      console.error("Error fetching states:", error.message);
    }
  };

  const fetchDistricts = async (stateCode) => {
    try {
      const headers = new Headers();
      headers.append(
        "X-CSCAPI-KEY",
        "WkE5bHg2VmFIWGZTRFAySTIzeW9uRTRCOVJxR1NRbW5rcWRweHYxZg=="
      );

      const response = await fetch(
        `https://api.countrystatecity.in/v1/countries/IN/states/${stateCode}/cities`,
        { headers }
      );
      const data = await response.json();
      const formattedDistricts = data.map((district) => ({
        value: district.name,
        label: district.name,
      }));
      setDistricts(formattedDistricts);
    } catch (error) {
      console.error("Error fetching districts:", error);
    }
  };

  const fetchAreaAndPincodesByDistrict = async (districtName) => {
    try {
      const response = await fetch(
        `https://api.postalpincode.in/postoffice/${districtName}`
      );
      const data = await response.json();
      if (data && data[0].Status === "Success") {
        const postOffices = data[0].PostOffice;
        setStatePincodes(postOffices); // Set post office details
        setPincodes(postOffices.map((po) => po.Pincode)); // Extract only pincodes
      } else {
        setStatePincodes([]);
        setPincodes([]);
        console.error("Failed to fetch pincodes for district:", districtName);
      }
    } catch (error) {
      console.error("Error fetching area and pincodes:", error);
    }
  };

  const handleStateChange = (selectedState) => {
    setState(selectedState.value);
    setDistrict(""); // Reset district when state changes
    setDistricts([]); // Reset districts
    setStatePincodes([]); // Reset pincodes
    setSelectedPincodes([]); // Reset selected pincodes
    fetchDistricts(selectedState.value); // Fetch districts when state changes
  };

  const handleDistrictChange = (selectedDistrict) => {
    setDistrict(selectedDistrict.value);
    setSelectedPincodes([]); // Reset selected pincodes
    fetchAreaAndPincodesByDistrict(selectedDistrict.value); // Fetch pincodes for the selected district
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedPincodes(statePincodes.map((po) => po.Pincode));
    } else {
      setSelectedPincodes([]);
    }
  };

  const handleSelectPincode = (pincode) => {
    setSelectedPincodes((prevSelected) =>
      prevSelected.includes(pincode)
        ? prevSelected.filter((code) => code !== pincode)
        : [...prevSelected, pincode]
    );
  };

  const handleSubmit = async () => {
    const pincodeData = {
      pincode: selectedPincodes, // Only post selected pincodes
      shipmentAmount: parseInt(amt, 10),
      isAvailable: "Yes",
      area: district,
      state: state,
    };

    try {
      const response = await fetch(
        isEditing
          ? `${process.env.REACT_APP_FOS_CLIENT_API}/pincode/${currentPincodeId}?tenantName=${TenantId}`
          : `${process.env.REACT_APP_FOS_CLIENT_API}/pincode?tenantName=${TenantId}`,
        {
          method: isEditing ? "PUT" : "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(pincodeData),
        }
      );

      if (response.ok) {
        navigate(`/apps/ListLocation`);
        resetForm();
        // Optionally fetch updated data here
      } else {
        const errorData = await response.json();
        console.error("Error submitting pincode:", errorData);
      }
    } catch (error) {
      console.error("Error submitting pincode:", error);
    }
  };

  const resetForm = () => {
    setAmt("");
    setState("");
    setDistrict("");
    setDistricts([]);
    setStatePincodes([]);
    setSelectedPincodes([]);
    setIsEditing(false);
    setCurrentPincodeId(null);
  };

  const totalPages = Math.ceil(statePincodes.length / itemsPerPage);

  useEffect(() => {
    // Ensure currentPage doesn't exceed totalPages
    if (currentPage > totalPages && totalPages > 0) {
      setCurrentPage(totalPages);
    }
  }, [currentPage, totalPages]);

  const indexOfLastItem = Math.min(currentPage * itemsPerPage, statePincodes.length);
  const indexOfFirstItem = Math.max(indexOfLastItem - itemsPerPage, 0);
  const currentItems = statePincodes?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <PageTitle
        breadCrumbItems={[
          { label: "app", path: "/apps/location" },
          { label: "Location", path: "/apps/Location", active: true },
        ]}
        title={"Available Pincodes"}
      />

      <Card>
        <Card.Body>
          <Form>
            <Form.Group className="mt-3">
              <Form.Label>State</Form.Label>
              <Select
                value={states.find((s) => s.value === state)}
                onChange={handleStateChange}
                options={states}
                placeholder="Select State"
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>District</Form.Label>
              <Select
                value={districts.find((d) => d.value === district)}
                onChange={handleDistrictChange}
                options={districts}
                placeholder="Select District"
                isDisabled={!state} // Disable if no state selected
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>Shipment Amount</Form.Label>
              <Form.Control
                value={amt}
                onChange={(e) => setAmt(e.target.value)}
                type="number"
                placeholder="Enter Shipment Amount"
              />
            </Form.Group>
            <div className="mt-3">
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-primary"
                disabled={!district || selectedPincodes.length === 0} // Disable button if no district or shipment amount is selected
              >
                Submit
              </button>
            </div>
          </Form>
        </Card.Body>
      </Card>

      {/* Table to display pincodes */}
      <Card>
        <Card.Body>
          <Table responsive className="table-centered table-nowrap mb-0">
            <thead className="table-light">
              <tr>
                <th>
                  <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={
                      selectedPincodes.length === statePincodes.length &&
                      statePincodes.length > 0
                    }
                  />
                </th>
                <th>Pincode</th>
                <th>Office</th>
                <th>Division</th>
              </tr>
            </thead>
            <tbody>
              {currentItems?.map((postOffice, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedPincodes.includes(postOffice.Pincode)}
                      onChange={() => handleSelectPincode(postOffice.Pincode)}
                    />
                  </td>
                  <td>{postOffice.Pincode}</td>
                  <td>{postOffice.Name}</td>
                  <td>{postOffice.Division}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="mt-4">
            {totalPages > 1 && (
              <Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                total={statePincodes.length}
                onChange={handlePageChange}
              />
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default PincodeCheck;
