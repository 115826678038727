
import { Card, Col, Row, Button } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import preview3 from "./img/preview3.png";
import preview2 from "./img/preview2.png";
import preview4 from "./img/preview4.png";
import "./ThemeSelect.css"; // Import the CSS file

function MoreTheme() {
  return (
    <Card>
    <Card.Header className="bg-white">
      <FeatherIcon icon="layout" className="text-primary me-1" />
      Themes
    </Card.Header>
    <Card.Body>
      <Row>
        <Col md={4}>
          <Card className="theme-card-container">
            <Card.Body>
              <div className="text-center position-relative">
                <img className="w-75" src={preview3} alt="" />
                <div className="theme-preview-overlay">
                  <Link
                    className="text-white"
                    onClick={() => window.open("http://195.35.22.221:3006", "_blank", "noopener,noreferrer")}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FeatherIcon icon="layout" className="fs-1 me-1" />
                    Click to Preview
                  </Link>
                </div>
              </div>
            </Card.Body>
            <Card.Footer className="bg-white d-flex align-items-center justify-content-between">
              <div>
                <Link to="/">Theme Name</Link>
              </div>
              <Button
                     className="btn btn-sm btn-soft-primary text-end" 
              >
                Add
              </Button>
            </Card.Footer>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="theme-card-container">
            <Card.Body>
              <div className="text-center position-relative">
                <img className="w-75" src={preview2} alt="" />
                <div className="theme-preview-overlay">
                  <Link
                    className="text-white"
                    to="http://195.35.22.221:3006"
                  >
                    <FeatherIcon icon="layout" className="fs-1 me-1" />
                    Click to Preview
                  </Link>
                </div>
              </div>
            </Card.Body>
            <Card.Footer className="bg-white d-flex align-items-center justify-content-between">
              <div>
                <Link to="/">Theme Name</Link>
              </div>
              <Button
                className="btn btn-sm btn-soft-primary text-end"
              
              >
                Add
              </Button>
            </Card.Footer>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="theme-card-container">
            <Card.Body>
              <div className="text-center position-relative">
                <img className="w-75" src={preview4} alt="" />
                <div className="theme-preview-overlay">
                  <Link
                    className="text-white"
                    to="https://demo.shoppingsto.com/"
                  >
                    <FeatherIcon icon="layout" className="fs-1 me-1" />
                    Click to Preview
                  </Link>
                </div>
              </div>
            </Card.Body>
            <Card.Footer className="bg-white d-flex align-items-center justify-content-between">
              <div>
                <Link to="/">Theme Name</Link>
              </div>
              <Button
                className="btn btn-sm btn-soft-primary text-end"
              >
                Add
              </Button>
            </Card.Footer>
          </Card>
        
        </Col>
      </Row>
    </Card.Body>
  </Card>
  )
}

export default MoreTheme