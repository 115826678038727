import React from 'react'

function SetUp() {
  return (
    <div>
      SetUp
    </div>
  )
}

export default SetUp