import React from 'react';
import './conbody.css'; // Make sure to add your CSS file
import img from './OBJECTS.png'; // Import your image file correctly
import logo from './logo5.png';
import { Link } from 'react-router-dom';

function ConBody({handleLandingPage}) {
  return (
    <div className="main-container">
  
    <header className="navbar">
        <div className="logo"> {/* Placeholder for your logo */}
          <img src={logo} alt="Logo" className="logo-img" />
        </div>
        <nav className="nav-list">
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#products">Products</a></li>
            <li><a href="#support">Support</a></li>
            <li><a href="#about">About</a></li>
            <li><Link to="/" className="quote-button text-light">Login</Link></li>
          </ul>
        </nav>
      </header>
      <div className="hero-section">
        
        <div className="text-content mb-5">
          <h1>Create a stores without  <span className="highlight">limits</span></h1>
          <p className="description">
            Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries
          </p>
          <div className="email-input-container">
            <button onClick={handleLandingPage} className="download-button">Get Start</button>
          </div>
        </div>
        <div className="image-content">
          <img src={img} alt="3D Shapes" className="w-100" />
        </div>
      </div>
    </div>
  );
}

export default ConBody;
