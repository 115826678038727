import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Progress } from "antd";
import "antd/dist/reset.css";
import "./intro.css";

function Intro() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [otherInput, setOtherInput] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [error, setError] = useState(false);

  const steps = [
    {
      id: 1,
      question: "What is your primary goal on our platform?",
      options: ["I want to set up a shop and sell products"],
      video:
        "https://video.wixstatic.com/video/a12ee7_0ec63af1b1e34446ae626fd5f6478897/file",
    },
    {
      id: 2,
      question: "What type of products do you plan to sell?",
      options: [
        "Electronics",
        "Clothing & Accessories",
        "Home & Garden",
        "Beauty & Health",
        "Sports & Outdoor",
        "Books & Media",

      ],
      video:
        "https://video.wixstatic.com/video/a12ee7_2c140b7b7a9e41078852a9c00ccc45ea/file",
    },
    {
      id: 3,
      question: "How many products do you plan to list on our platform?",
      options: [
        "We are using fewer than 10 products",
        "We are using between 10 and 50 products",
        "We are using between 50 and 100 products",
        "We are using more than 100 products",
      ],
      video:
        "https://video.wixstatic.com/video/a12ee7_4adb81be8d6943cd98145bf5a859be83/file",
    },
 {
    id: 4,
    question: "Which country will your store primarily operate in?",
    options: [
     "India",
     "UK",
     "USA",
     "Canada",
     "Australia",
    ],
    video:
      "https://video.wixstatic.com/video/a12ee7_6a7f9c8f4cbf48b7b6ed56bf983b3ddf/file",
  },
  ];
  console.log(sessionStorage.getItem("admin", "adminid"));

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setOtherInput("");
    setError(false);
  };

  const handleOtherInputChange = (event) => {
    setSelectedOption("Other");
    setOtherInput(event.target.value);
    setError(false);
  };

  const updateServer = async (queries) => {
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_FOS_CLIENT_API
        }/details-cus/${localStorage.getItem("TenantId")}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ queries }),
        }
      );

      const Introresponse = await fetch(
        `${process.env.REACT_APP_FOS_CLIENT_API}/admin/${sessionStorage.getItem(
          "admin"
        )}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ introcheck: "true" }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update queries");
      }
    } catch (error) {
      console.error("Error updating queries:", error);
    }
  };

  const handleNext = () => {
    if (selectedOption) {
      const finalOption =
        selectedOption === "Other" ? otherInput : selectedOption;
      const updatedOptions = [...selectedOptions, finalOption];
      setSelectedOptions(updatedOptions);

      if (currentStep < steps.length - 1) {
        setCurrentStep(currentStep + 1);
        setSelectedOption("");
        setOtherInput("");
      } else {
        handleFinish(updatedOptions);
      }
    } else {
      setError(true);
    }
  };

  const handleSkip = () => {
    handleFinish(selectedOptions);
  };

  const handleFinish = async (options) => {
    setLoading(true);

    // Increment progress up to 90% while waiting for the server response
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 90) {
          clearInterval(progressInterval);
          return 90; // Stop at 90% while waiting for the server response
        }
        return prevProgress + 10;
      });
    }, 300);

    // Send all selected options at once
    await updateServer(options);

    // Complete progress to 100% after the server response
    setProgress(100);
    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  return (
    <div className="intro-container">
      {!loading ? (
        <>
          <div className="question-section">
            <h2>{steps[currentStep].question}</h2>
            <div className={`mt-5 options ${error ? "error" : ""}`}>
              {steps[currentStep].options.map((option, index) => (
                <div
                  key={index}
                  className={`option ${
                    selectedOption === option ? "selected" : ""
                  }`}
                  onClick={() => handleOptionSelect(option)}
                >
                  <label htmlFor={`option-${index}`}>{option}</label>
                </div>
              ))}
              <div
                className={`option ${
                  selectedOption === "Other" ? "selected" : ""
                }`}
                onClick={() => handleOptionSelect("Other")}
              >
                <span>Other</span>
                {selectedOption === "Other" && (
                  <input
                    type="text"
                    className="other-input ms-2 no-border"
                    placeholder=""
                    value={otherInput}
                    onChange={handleOtherInputChange}
                  />
                )}
              </div>
            </div>
            {error && (
              <p className="error-message">
                Please select an option before continuing.
              </p>
            )}
            <button type="button" className="continue-btn" onClick={handleNext}>
              {currentStep < steps.length - 1 ? "Continue" : "Finish"}
            </button>
          </div>
          <div className="image-section">
            <video
              src={steps[currentStep].video}
              autoPlay
              loop
              muted
              playsInline
              className="video-background w-75"
            />
          </div>
          {currentStep < steps.length - 1 && (
            <button
              type="button"
              className="skip-btn bg-light rounded-5"
              onClick={handleSkip}
            >
              Skip
            </button>
          )}
        </>
      ) : (
        <div className="loader">
          <Progress
            type="circle"
            percent={progress}
            strokeColor={{
              "0%": "#108ee9",
              "100%": "#87d068",
            }}
          />
          <div className="progress-message">
            <p className="text-dark">Your Dashboard is Almost Ready...</p>
            <p>{progress}%</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Intro;
