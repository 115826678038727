import React from "react";
import PageTitle from "../../../components/PageTitle";
import { Card, Button, Form, Dropdown, Col, Row } from "react-bootstrap"; // Import Bootstrap for styling
import { Link } from "react-router-dom";

function BlogPost() {
  const blogPosts = [
    {
      id: 1,
      title: "The Future of AI in E-commerce",
      excerpt:
        "Artificial intelligence is revolutionizing the e-commerce industry in numerous ways...",
      date: "September 16, 2024",
      image: "https://via.placeholder.com/300x200", // Placeholder image URL
    },
    {
      id: 2,
      title: "Understanding Customer Behavior",
      excerpt:
        "By analyzing customer data, businesses can tailor their strategies for better engagement...",
      date: "September 12, 2024",
      image: "https://via.placeholder.com/300x200",
    },
    {
      id: 2,
      title: "Understanding Customer Behavior",
      excerpt:
        "By analyzing customer data, businesses can tailor their strategies for better engagement...",
      date: "September 12, 2024",
      image: "https://via.placeholder.com/300x200",
    },
  ];

  return (
    <div>
      <PageTitle
        breadCrumbItems={[
          { label: "app", path: "/apps/Blog" },
          { label: "Customers", path: "/apps/Blog", active: true },
        ]}
        title={"Blog"}
      />
      <Card className="mb-3">
        <Card.Body>
          <Row className="align-items-center">
            <Col sm={3}>
              <Link to="/tables/AddBlog" className="btn btn-primary">
                <i className="uil uil-plus me-1"></i> Add Blog
              </Link>
            </Col>
            <Col sm={9}>
              <div className="float-sm-end mt-3 mt-sm-0">
                <div className="task-search d-inline-block me-sm-1 mb-3">
                  <form>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control search-input"
                        placeholder="Search Pincode..."
                        // value={searchQuery}
                      />
                      <Button
                        variant="primary"
                        className="input-group-text"
                        type="button"
                      >
                        <i className="uil uil-search"></i>
                      </Button>
                    </div>
                  </form>
                </div>
                <Dropdown className="d-inline-block" align="end">
                  <Dropdown.Toggle
                    variant="secondary"
                    className="cursor-pointer"
                  >
                    <i className="uil uil-sort-amount-down"></i> Sort By
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>Shipment Amount</Dropdown.Item>
                    <Dropdown.Item>Availability</Dropdown.Item>
                    <Dropdown.Item>State</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

    <Card>
        <Card.Body>
      {/* Blog Post Cards */}
      <div className="row">
        {blogPosts.map((post) => (
          <div className="col-md-6 col-lg-4 mb-4" key={post.id}>
            <Card className="h-100">
              <Card.Img variant="top" src={post.image} />
              <Card.Body>
                <Card.Title>{post.title}</Card.Title>
                <Card.Text>{post.excerpt}</Card.Text>
                <Button variant="primary">Read More</Button>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">Published on {post.date}</small>
              </Card.Footer>
            </Card>
          </div>
        ))}
      </div>
        </Card.Body>
    </Card>
    </div>
  );
}

export default BlogPost;
