import React from 'react'
import PageTitle from '../../../components/PageTitle'

function SupportCus() {
  return (
    <div>
           <PageTitle
        breadCrumbItems={[
          { label: "app", path: "/apps/support" },
          { label: "Domain", path: "/apps/support", active: true },
        ]}
        title={"Support"}
      />
    </div>
  )
}

export default SupportCus