import React, { useEffect, useState } from 'react';
import { Accordion, Card, Button, Spinner, Alert } from 'react-bootstrap';
import moment from 'moment';
import PageTitle from '../../../components/PageTitle';
import { FaTrash } from 'react-icons/fa'; // For delete icon
import { Rate, Modal } from 'antd'; // Import Modal from Ant Design
import { toast, ToastContainer } from 'react-toastify'; // Import Toastify components
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS

function Reviews() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_FOS_CLIENT_API}/products?tenantName=${localStorage.getItem('TenantId')}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        setError('Error fetching products');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const deleteReview = async (reviewId, productId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_FOS_CLIENT_API}/review/${reviewId}?tenantName=${localStorage.getItem('TenantId')}`, {
        method: 'DELETE',
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      setProducts(products.map(product => 
        product.id === productId 
          ? { ...product, Reviews: product.Reviews.filter(review => review.reviewId !== reviewId) }
          : product
      ));
      toast.success('Review deleted successfully'); // Show success notification
    } catch (error) {
      console.error('Error deleting review:', error);
      toast.error('Failed to delete review'); // Show error notification
    }
  };

  const handleDeleteClick = (reviewId, productId) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this review?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteReview(reviewId, productId); // Call delete function if confirmed
      },
      onCancel() {
        console.log('Delete cancelled');
      },
    });
  };


  if (error) return <Alert variant="danger">{error}</Alert>;

  return (
    <div>
      <PageTitle
        breadCrumbItems={[
          { label: "app", path: "/apps/Orders" },
          { label: "Customers", path: "/apps/Reviews", active: true },
        ]}
        title={"Reviews"}
      />
      <Card>
        <Card.Body>
          <div style={{ padding: '20px' }}>
            {products.length === 0 || products.every(product => product.Reviews.length === 0) ? (
              <Alert variant="info">No Reviews Available</Alert>
            ) : (
              <Accordion defaultActiveKey="0">
                {products.filter(product => product.Reviews.length > 0).map(product => (
                  <Accordion.Item eventKey={product.id.toString()} key={product.id}>
                    <Accordion.Header>{product.name}</Accordion.Header>
                    <Accordion.Body>
                      {product.Reviews.map(review => (
                        <Card key={review.reviewId} style={{ marginBottom: '1rem' }}>
                          <Card.Body>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <div>
                                <strong>Rating:</strong> <Rate disabled defaultValue={review.rating} />
                              </div>
                              <Button variant="danger" onClick={() => handleDeleteClick(review.reviewId, product.id)}>
                                <FaTrash /> Delete
                              </Button>
                            </div>
                            <p>{review.comment}</p>
                            <p>{moment(review.reviewDate).format('YYYY-MM-DD HH:mm')}</p>
                            <p>User Email: {review.User?.email || 'N/A'}</p>
                          </Card.Body>
                        </Card>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            )}
          </div>
        </Card.Body>
      </Card>
      <ToastContainer /> {/* Include ToastContainer to display notifications */}
    </div>
  );
}

export default Reviews;
