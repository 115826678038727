import React, { useState } from 'react';
import { Form, Button, Card, Row, Col } from 'react-bootstrap';

function AddBlog() {
  const [blogTitle, setBlogTitle] = useState('');
  const [blogContent, setBlogContent] = useState('');
  const [blogImage, setBlogImage] = useState(null);
  const [contentList, setContentList] = useState([]);

  // Handle Image Upload
  const handleImageUpload = (e) => {
    setBlogImage(URL.createObjectURL(e.target.files[0]));
  };

  // Add Content to List
  const handleAddContent = () => {
    if (blogContent) {
      setContentList([...contentList, blogContent]);
      setBlogContent(''); // Clear content input after adding
    }
  };

  return (
    <div className="container my-4">
      <h3>Create Blog</h3>
      
      {/* Blog Form */}
      <Card className="p-4 mb-4">
        <Form>
          {/* Blog Title */}
          <Form.Group className="mb-3" controlId="formBlogTitle">
            <Form.Label>Blog Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter blog title"
              value={blogTitle}
              onChange={(e) => setBlogTitle(e.target.value)}
            />
          </Form.Group>

          {/* Blog Image Upload */}
          <Form.Group className="mb-3" controlId="formBlogImage">
            <Form.Label>Upload Blog Image</Form.Label>
            <Form.Control type="file" onChange={handleImageUpload} />
            {blogImage && (
              <div className="mt-3">
                <img src={blogImage} alt="Blog Preview" style={{ width: '100%', maxHeight: '300px', objectFit: 'cover' }} />
              </div>
            )}
          </Form.Group>

          {/* Blog Content */}
          <Form.Group className="mb-3" controlId="formBlogContent">
            <Form.Label>Blog Content</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Write your blog content..."
              value={blogContent}
              onChange={(e) => setBlogContent(e.target.value)}
            />
          </Form.Group>

          <Button variant="outline-primary" onClick={handleAddContent}>
            + Add Content
          </Button>
        </Form>
      </Card>

      {/* Display Added Content */}
      {contentList.length > 0 && (
        <div className="mb-4">
          <h4>Added Content</h4>
          <ul className="list-group">
            {contentList.map((content, index) => (
              <li key={index} className="list-group-item">
                {content}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Blog Preview */}
      <h3>Blog Preview</h3>
      <Card className="p-4 mb-4">
        {blogImage && (
          <Card.Img variant="top" src={blogImage} alt="Blog Preview" style={{ maxHeight: '300px', objectFit: 'cover' }} />
        )}
        <Card.Body>
          <Card.Title>{blogTitle || 'Your Blog Title'}</Card.Title>
          {contentList.length > 0 ? (
            <div>
              {contentList.map((content, index) => (
                <p key={index}>{content}</p>
              ))}
            </div>
          ) : (
            <Card.Text>No content added yet.</Card.Text>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default AddBlog;
