import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Nav,
  Tab,
  Table,
  Modal,
  Button,
  Spinner,
  Pagination,
} from "react-bootstrap";
import PageTitle from "../../../components/PageTitle";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Toastify CSS
import { Modal as AntdModal } from "antd"; // Ant Design Modal for confirmation
import { useReactToPrint } from "react-to-print";
import InvoicePrintComponent from "./PaginationComponent";
function OrderDetails() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(10); // Adjust this value to control how many orders per page
  const invoiceRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
  });
  useEffect(() => {
    // Fetch orders
    const fetchOrders = async () => {
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_FOS_CLIENT_API
          }/orders/?tenantName=${localStorage.getItem("TenantId")}`
        );
        const data = await response.json();
        setOrders(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching orders:", error);
        setLoading(false);
      }
    };
    fetchOrders();
  }, []);

  // Function to fetch order details by orderId
  const fetchOrderDetails = async (orderId) => {
    setLoadingDetails(true);
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_FOS_CLIENT_API
        }/orders/${orderId}?tenantName=${localStorage.getItem("TenantId")}`
      );
      const data = await response.json();
      setSelectedOrder(data);
      setLoadingDetails(false);
    } catch (error) {
      console.error("Error fetching order details:", error);
      setLoadingDetails(false);
    }
  };

  // Function to handle showing the details modal
  const handleShowDetails = (orderId) => {
    fetchOrderDetails(orderId);
    setModalShow(true);
  };

  // Function to handle approving the order
  // Function to handle approving the order
  const handleApproveOrder = async (orderId) => {
    AntdModal.confirm({
      title: "Are you sure you want to approve this order?",
      content: 'Once approved, the order status will be updated to "shipped".',
      onOk: async () => {
        try {
          const response = await fetch(
            `${
              process.env.REACT_APP_FOS_CLIENT_API
            }/orders/${orderId}?tenantName=${localStorage.getItem("TenantId")}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ status: "shipped" }),
            }
          );

          if (response.ok) {
            const updatedOrders = orders.map((order) =>
              order.orderId === orderId
                ? { ...order, status: "shipped" }
                : order
            );
            setOrders(updatedOrders);
            toast.success("Order approved and marked as shipped!");
          } else {
            toast.error("Failed to approve the order");
          }
        } catch (error) {
          console.error("Error approving the order:", error);
          toast.error("Error approving the order");
        }
      },
    });
  };

  const handleShippedOrder = async (orderId) => {
    AntdModal.confirm({
      title: "Are you sure you want to mark this order as completed?",
      content:
        'Once completed, the order status will be updated to "completed".',
      onOk: async () => {
        try {
          const response = await fetch(
            `${
              process.env.REACT_APP_FOS_CLIENT_API
            }/orders/${orderId}?tenantName=${localStorage.getItem("TenantId")}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ status: "completed" }),
            }
          );

          if (response.ok) {
            const updatedOrders = orders.map((order) =>
              order.orderId === orderId
                ? { ...order, status: "completed" }
                : order
            );
            setOrders(updatedOrders);
            toast.success("Order status updated to completed!");
          } else {
            toast.error("Failed to update order status");
          }
        } catch (error) {
          console.error("Error updating order status:", error);
          toast.error("Error updating order status");
        }
      },
    });
  };

  // Pagination logic
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = (status) =>
    orders
      .filter((order) => order.status === status)
      .slice(indexOfFirstOrder, indexOfLastOrder);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderOrdersByStatus = (status) => {
    return currentOrders(status).map((order) => (
      <tr key={order.orderId}>
        <td>{order.User.name}</td>
        <td>{order.ShippingAddress.Phone}</td>
        <td>{order.User.email}</td>
        <td>${order.totalAmount}</td>
        <td>{order.paymentmtd}</td>
        <td className="text-center">
          <button
            className="btn btn-outline-success"
            onClick={() => handleShowDetails(order.orderId)}
          >
            Details
          </button>

          {/* Show 'Approve' button if order is not shipped or completed */}
          {order.status === "ordered" && (
            <button
              className="btn btn-soft-info ms-2"
              onClick={() => handleApproveOrder(order.orderId)}
            >
              Approve
            </button>
          )}

          {/* Show 'Shipped' button if order is shipped but not completed */}
          {order.status === "shipped" && (
           
              <button
                className="btn btn-soft-warning ms-2"
                onClick={() => handleShippedOrder(order.orderId)}
              >
                Complete
              </button>
  
          )}

          {/* No buttons if order is completed */}
        </td>
      </tr>
    ));
  };

  if (loading) {
    return <div>Loading orders...</div>;
  }

  const tabContents = [
    {
      title: "Order Process",
      icon: "bi bi-hourglass-split",
      content: (
        <div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Total Amount</th>
                <th>Payment Method</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{renderOrdersByStatus("ordered")}</tbody>
          </Table>
          <PaginationComponent
            currentPage={currentPage}
            ordersPerPage={ordersPerPage}
            totalOrders={
              orders.filter((order) => order.status === "ordered").length
            }
            paginate={paginate}
          />
        </div>
      ),
    },
    {
      title: "Shipment Process",
      icon: "bi bi-truck",
      content: (
        <div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Total Amount</th>
                <th>Payment Method</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{renderOrdersByStatus("shipped")}</tbody>
          </Table>
          <PaginationComponent
            currentPage={currentPage}
            ordersPerPage={ordersPerPage}
            totalOrders={
              orders.filter((order) => order.status === "shipped").length
            }
            paginate={paginate}
          />
        </div>
      ),
    },
    {
      title: "Completed",
      icon: "bi bi-credit-card",
      content: (
        <div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Total Amount</th>
                <th>Payment Method</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{renderOrdersByStatus("completed")}</tbody>
          </Table>
          <PaginationComponent
            currentPage={currentPage}
            ordersPerPage={ordersPerPage}
            totalOrders={
              orders.filter((order) => order.status === "completed").length
            }
            paginate={paginate}
          />
        </div>
      ),
    },
  ];
  const InvoiceComponent = React.forwardRef((props, ref) => (
    <div ref={ref}>
      <h1>Invoice</h1>
      {selectedOrder && (
        <div>
          <h2>Order ID: {selectedOrder.orderId}</h2>
          <p>
            <strong>Name:</strong> {selectedOrder.User.name}
          </p>
          <p>
            <strong>Email:</strong> {selectedOrder.User.email}
          </p>
          <p>
            <strong>Total Amount:</strong> ${selectedOrder.totalAmount}
          </p>
          <p>
            <strong>Payment Method:</strong> {selectedOrder.paymentmtd}
          </p>
          <p>
            <strong>Address:</strong> {selectedOrder.ShippingAddress.address}
          </p>
          <p>
            <strong>Phone:</strong> {selectedOrder.ShippingAddress.Phone}
          </p>
        </div>
      )}
    </div>
  ));
  // Modal content for order details
  const OrderDetailsModal = ({ show, onHide, order }) => (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Order Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loadingDetails ? (
          <div className="text-center">
            <Spinner animation="border" />
            <p>Loading order details...</p>
          </div>
        ) : order ? (
          <div>
            <h5>Customer Information</h5>
            <p>Name: {order.User.name}</p>
            <p>Email: {order.User.email}</p>
            <p>Phone: {order.ShippingAddress.Phone}</p>
            <p>
              Address: {order.ShippingAddress.address},{" "}
              {order.ShippingAddress.city}, {order.ShippingAddress.state},{" "}
              {order.ShippingAddress.postalCode}
            </p>

            <h5>Products</h5>
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Quantity</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {order.OrderItems &&
                  order.OrderItems.map((product) => (
                    <tr key={product.productId}>
                      <td>{product.Product.name}</td>
                      <td>{product.quantity}</td>
                      <td>${product.price}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            {loadingDetails ? (
            <Spinner animation="border" />
          ) : (
            <div>
              <InvoicePrintComponent order={selectedOrder} ref={invoiceRef} />
            </div>
          )}
            <h5>Total: ${order.totalAmount}</h5>
            <button
              onClick={handlePrint}
              className="btn btn-outline-primary mt-2"
            >
              Download Invoice
            </button>
          </div>
        ) : (
          <p>No order details available.</p>
        )}

      
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );

 
  return (
    <div>
      <PageTitle
        breadCrumbItems={[
          { label: "app", path: "/apps/Orders" },
          { label: "Orders", path: "/apps/Orders", active: true },
        ]}
        title={"Order List"}
      />
      <Card>
        <Card.Body>
          <Tab.Container defaultActiveKey="Order Process">
            <Nav as="ul" variant="tabs">
              {tabContents.map((tab, index) => (
                <Nav.Item as="li" key={index}>
                  <Nav.Link eventKey={tab.title}>
                    <i style={{ marginRight: "4px" }} className={`${tab.icon}`}>
                      {" "}
                    </i>{" "}
                    {tab.title}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
            <Tab.Content>
              {tabContents.map((tab, index) => (
                <Tab.Pane eventKey={tab.title} key={index}>
                  {tab.content}
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Tab.Container>
        </Card.Body>
      </Card>

      <OrderDetailsModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        order={selectedOrder}
      />
    </div>
  );
}

// Pagination component
const PaginationComponent = ({
  currentPage,
  ordersPerPage,
  totalOrders,
  paginate,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalOrders / ordersPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <Pagination>
      {pageNumbers.map((number) => (
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => paginate(number)}
        >
          {number}
        </Pagination.Item>
      ))}
    </Pagination>
  );
};

export default OrderDetails;
