import React, { useState } from "react";
import PageTitle from "../../../components/PageTitle";
import { Card, Col, Row, Button } from "react-bootstrap";
import { Modal } from "antd";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import bg from "./img/profile lg.png";
import bg2 from "./img/profile4sm.png";
import preview3 from "./img/preview3.png";
import preview2 from "./img/preview2.png";
import preview4 from "./img/preview4.png";
import "./ThemeSelect.css"; // Import the CSS file
import Pricing from "../Pricing";

function ThemeSelect() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(null);

  const showModal = (theme) => {
    setSelectedTheme(theme);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    // Handle "Ok" button click logic here
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <PageTitle
        breadCrumbItems={[
          { label: "app", path: "/apps/Themes" },
          { label: "Customers", path: "/apps/Themes", active: true },
        ]}
        title={"Themes"}
      />
      <Card>
        <Card.Body>
          <div className="row">
            <img className="col-8" src={bg} alt="" />
            <img className="col-4" src={bg2} alt="" />
          </div>
          <div className="alert alert-warning mt-3" role="alert">
            Your online store is in development. To let visitors access your
            store.
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header className="bg-white">
          <FeatherIcon icon="credit-card" className="text-primary me-1" />
          Current Theme
        </Card.Header>
        <Card.Body>
          <div className="row">
            <div className="col-3 border p-2">
              <img className="w-100" src={bg} alt="" />
            </div>
            <div className="col-7">
              <strong>Dawn</strong>
              <div>
                <small>Added: 48 minutes ago</small>
              </div>
              <div className="mt-1">
                <span>Dawn Version 1.3</span>
              </div>
            </div>
            <div className="col-2">
              <div className="mt-1">
                <Button className="btn btn-sm btn-soft-info rounded-pill">
                  Preview
                </Button>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header className="bg-white">
          <FeatherIcon icon="layout" className="text-primary me-1" />
          Themes
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={4}>
              <Card className="theme-card-container">
                <Card.Body>
                  <div className="text-center position-relative">
                    <img className="w-75" src={preview3} alt="" />
                    <div className="theme-preview-overlay">
                      <a
                        className="text-white"
                        href="http://195.35.22.221:3006"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FeatherIcon icon="layout" className="fs-1 me-1" />
                        Click to Preview
                      </a>
                    </div>
                  </div>
                </Card.Body>
                <Card.Footer className="bg-white d-flex align-items-center justify-content-between">
                  <div>
                    <Link to="/">Theme Name</Link>
                  </div>
                  <Button
                    className="btn btn-sm btn-soft-primary text-end"
                    onClick={() => showModal("Theme Name")}
                  >
                    Add
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="theme-card-container">
                <Card.Body>
                  <div className="text-center position-relative">
                    <img className="w-75" src={preview2} alt="" />
                    <div className="theme-preview-overlay">
                    <a
                        className="text-white"
                        href="http://195.35.22.221:3006"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FeatherIcon icon="layout" className="fs-1 me-1" />
                        Click to Preview
                      </a>
                    </div>
                  </div>
                </Card.Body>
                <Card.Footer className="bg-white d-flex align-items-center justify-content-between">
                  <div>
                    <Link to="/">Theme Name</Link>
                  </div>
                  <Button
                    className="btn btn-sm btn-soft-primary text-end"
                    onClick={() => showModal("Theme Name")}
                  >
                    Add
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="theme-card-container">
                <Card.Body>
                  <div className="text-center position-relative">
                    <img className="w-75" src={preview4} alt="" />
                    <div className="theme-preview-overlay">
                    <a
                        className="text-white"
                        href="https://demo.shoppingsto.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FeatherIcon icon="layout" className="fs-1 me-1" />
                        Click to Preview
                      </a>
                    </div>
                  </div>
                </Card.Body>
                <Card.Footer className="bg-white d-flex align-items-center justify-content-between">
                  <div>
                    <Link to="/">Theme Name</Link>
                  </div>
                  <Button
                    className="btn btn-sm btn-soft-primary text-end"
                    onClick={() => showModal("Theme Name")}
                  >
                    Add
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <div className="text-end">
            <Link to="/theme">More Themes...</Link>
          </div>
        </Card.Body>
      </Card>

      {/* Ant Design Modal */}
      <Modal
        className="w-75"
        title="Add Theme"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Pricing />
      </Modal>
    </div>
  );
}

export default ThemeSelect;
