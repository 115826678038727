import React from 'react'
import bg2 from '../../../../assets/images/bg/blender-dynamic-premium.png';
import bg3 from '../../../../assets/images/bg/hash-dynamic-premium.png';
import bg4 from '../../../../assets/images/bg/setting-dynamic-premium.png';
import bg1 from '../../../../assets/images/bg/at-dynamic-premium.png'

function Bg() {
  return (
    <div>
              <img src={bg2} alt="img" className="position-absolute left bottom-0 w-25" />
      <img
        src={bg1}
        style={{ width: '10%' }}
        alt="img"
        className="position-absolute left-50 top-0"
      />
      <img
        src={bg3}
        style={{ width: '8%', left: '10%', top: '30%' }}
        alt="img"
        className="position-absolute "
      />
      <img
        src={bg4}
        style={{ width: '20%', left: '30%', zIndex: '22' }}
        alt="img"
        className="position-absolute"
      />
    </div>
  )
}

export default Bg