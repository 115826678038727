import React, { useState, useEffect } from "react";
import PageTitle from "../../../components/PageTitle";
import {
  Card,
  Form,
  Row,
  Col,
  Container,
  Button,
  ListGroup,
  Badge,
} from "react-bootstrap";
import FileUploader from "../../../components/FileUploader";
import ReactQuill from "react-quill";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS file for toastify
import VendorDetails from "./VendorDetails";

function ThemeContent() {
  // State for form fields
  const [companyName, setCompanyName] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyDetails, setCompanyDetails] = useState("");
  const [companyLocation, setCompanyLocation] = useState("");
  const [branches, setBranches] = useState([""]);
  const [headquarters, setHeadquarters] = useState("");
  const [socialMedia, setSocialMedia] = useState([""]);
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [policy, setPolicy] = useState("");
  const [vendorData, setVendorData] = useState(null); // State to hold fetched vendor data
  const tenantId = localStorage.getItem("TenantId");

  // Fetch vendor data
  useEffect(() => {
    const fetchVendorData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FOS_CLIENT_API}/vendor?tenantName=${tenantId}`
        );
        const data = await response.json();
        if (data.length > 0) {
          setVendorData(data[0]);
        }
      } catch (error) {
        console.error("Error fetching vendor data:", error);
      }
    };

    fetchVendorData();
  }, [tenantId]);

  // Add a new branch
  const addBranch = () => {
    setBranches([...branches, ""]);
  };

  // Remove a branch
  const removeBranch = (index) => {
    setBranches(branches.filter((_, i) => i !== index));
  };

  // Handle branch input change
  const handleBranchChange = (index, value) => {
    const updatedBranches = branches.map((branch, i) =>
      i === index ? value : branch
    );
    setBranches(updatedBranches);
  };

  // Add a new social media link
  const addSocialMedia = () => {
    setSocialMedia([...socialMedia, ""]);
  };

  // Remove a social media link
  const removeSocialMedia = (index) => {
    setSocialMedia(socialMedia.filter((_, i) => i !== index));
  };

  // Handle social media input change
  const handleSocialMediaChange = (index, value) => {
    const updatedSocialMedia = socialMedia.map((link, i) =>
      i === index ? value : link
    );
    setSocialMedia(updatedSocialMedia);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      companyName,
      companyLogo,
      companyDetails,
      address: companyLocation,
      socialMedia,
      email,
      contact,
      policy,
      branch: {
        headquarters,
        branches,
      },
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_FOS_CLIENT_API}/vendor?tenantName=${tenantId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        toast.success("Data submitted successfully!");
        // Reset form fields
        setCompanyName("");
        setCompanyLogo("");
        setCompanyDetails("");
        setCompanyLocation("");
        setBranches([""]);
        setHeadquarters("");
        setSocialMedia([""]);
        setEmail("");
        setContact("");
        setPolicy("");
      } else {
        toast.error("Error submitting data");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while submitting data");
    }
  };

  // Render vendor details if vendorData exists
  if (vendorData) {
    return (
     <VendorDetails  vendorData={vendorData} />
    
    );
  }

  // If vendorData is null, render the form
  return (
    <Container fluid className="my-4">
      <PageTitle
        breadCrumbItems={[
          { label: "app", path: "/apps/PageContent" },
          { label: "Customers", path: "/apps/Orders", active: true },
        ]}
        title={"Page Content"}
      />

      <Card className="shadow-sm">
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              {/* Form Section */}
              <Col lg={8} md={12} className="mb-4">
                <Form.Group className="mb-3">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your company"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Company Details</Form.Label>
                  <ReactQuill
                    value={companyDetails}
                    onChange={setCompanyDetails}
                    required
                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label>Company Location</Form.Label>
                  <ReactQuill
                    value={companyLocation}
                    onChange={setCompanyLocation}
                    required
                  />
                </Form.Group>

                {/* Branches Management */}
                <Form.Group className="mt-3">
                  <Form.Label>Branches</Form.Label>
                  {branches.map((branch, index) => (
                    <div key={index} className="d-flex mb-2">
                      <Form.Control
                        type="text"
                        placeholder="Enter branch location"
                        value={branch}
                        onChange={(e) =>
                          handleBranchChange(index, e.target.value)
                        }
                        className="me-2"
                      />
                      <Button
                        variant="danger"
                        onClick={() => removeBranch(index)}
                        disabled={branches.length === 1}
                      >
                        Remove
                      </Button>
                    </div>
                  ))}
                  <Button variant="primary" onClick={addBranch}>
                    Add Branch
                  </Button>
                </Form.Group>

                {/* Social Media Management */}
                <Form.Group className="mt-3">
                  <Form.Label>Social Media Links</Form.Label>
                  {socialMedia.map((link, index) => (
                    <div key={index} className="d-flex mb-2">
                      <Form.Control
                        type="text"
                        placeholder="Enter social media link"
                        value={link}
                        onChange={(e) =>
                          handleSocialMediaChange(index, e.target.value)
                        }
                        className="me-2"
                      />
                      <Button
                        variant="danger"
                        onClick={() => removeSocialMedia(index)}
                        disabled={socialMedia.length === 1}
                      >
                        Remove
                      </Button>
                    </div>
                  ))}
                  <Button variant="primary" onClick={addSocialMedia}>
                    Add Social Media
                  </Button>
                </Form.Group>

                {/* Other Fields */}
                <Form.Group className="mt-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label>Contact</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter contact number"
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label>Policy</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter policy"
                    value={policy}
                    onChange={(e) => setPolicy(e.target.value)}
                  />
                </Form.Group>
              </Col>

              {/* File Upload Section */}
              <Col lg={4} md={12} className="mb-4">
                <Card className="shadow-sm">
                  <Card.Body>
                    <Form.Group>
                      <FileUploader
                        onFileSelectSuccess={(file) =>
                          setCompanyLogo(file.name)
                        }
                        onFileSelectError={({ error }) => toast.error(error)}
                      />
                    </Form.Group>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Card.Body>
      </Card>

      {/* Toast container for displaying success/error messages */}
      <ToastContainer />
    </Container>
  );
}

export default ThemeContent;
