import PageTitle from "../../../components/PageTitle";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Row,
  Accordion,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

function ListPincode() {
  const [pincodes, setPincodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showMorePincodes, setShowMorePincodes] = useState({});
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  const PINCODES_TO_SHOW = 5; // Show the first 5 pincodes by default

  useEffect(() => {
    // Fetch the pincode data
    fetch(
      `${
        process.env.REACT_APP_FOS_CLIENT_API
      }/pincode?tenantName=${localStorage.getItem("TenantId")}`
    )
      .then((response) => response.json())
      .then((data) => {
        setPincodes(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching pincode data:", error);
        setLoading(false);
      });
  }, []);

  const handleShowMorePincodes = (pincodeId) => {
    setShowMorePincodes((prevState) => ({
      ...prevState,
      [pincodeId]: !prevState[pincodeId], // Toggle the state for each area
    }));
  };

  // Filter the pincodes based on the search query
  const filteredPincodes = pincodes.filter(
    (pincodeData) =>
      pincodeData.area.toLowerCase().includes(searchQuery.toLowerCase()) ||
      pincodeData.state.toLowerCase().includes(searchQuery.toLowerCase()) ||
      pincodeData.pincode.some((code) => code.includes(searchQuery))
  );

  return (
    <div>
      <PageTitle
        breadCrumbItems={[
          { label: "app", path: "/apps/store" },
          { label: "Product List", path: "/apps/store", active: true },
        ]}
        title={"Pincode List"}
      />
      <Card className="mb-4">
        <Card.Body>
          <Row className="align-items-center">
            <Col sm={3}>
              <Link to="/apps/location" className="btn btn-primary">
                <i className="uil uil-plus me-1"></i> Add Pincode
              </Link>
            </Col>
            <Col sm={9}>
              <div className="float-sm-end mt-3 mt-sm-0">
                <div className="task-search d-inline-block me-sm-1 mb-3">
                  <form>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control search-input"
                        placeholder="Search Pincode..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)} // Update search query state
                      />
                      <Button
                        variant="primary"
                        className="input-group-text"
                        type="button"
                      >
                        <i className="uil uil-search"></i>
                      </Button>
                    </div>
                  </form>
                </div>
                <Dropdown className="d-inline-block" align="end">
                  <Dropdown.Toggle
                    variant="secondary"
                    className="cursor-pointer"
                  >
                    <i className="uil uil-sort-amount-down"></i> Sort By
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>Shipment Amount</Dropdown.Item>
                    <Dropdown.Item>Availability</Dropdown.Item>
                    <Dropdown.Item>State</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* Pincode Accordion */}
      <Card className="mb-4">
        <Card.Body>
          <h5 className="mb-4">Pincode Areas</h5>
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" />
              <p>Loading pincodes...</p>
            </div>
          ) : filteredPincodes.length > 0 ? (
            <Accordion>
              {filteredPincodes.map((pincodeData, index) => (
                <Accordion.Item
                  eventKey={index.toString()}
                  key={pincodeData.pincodeId}
                >
                  <Accordion.Header>
                    <i className="uil uil-map-pin me-2"></i> {pincodeData.area}{" "}
                    ({pincodeData.state})
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col sm={6}>
                        <p>
                          <strong>Shipment Amount:</strong> ₹
                          {pincodeData.shipmentAmount}
                        </p>
                        <p>
                          <strong>Availability:</strong>{" "}
                          {pincodeData.isAvailable ? (
                            <span className="badge bg-success">Available</span>
                          ) : (
                            <span className="badge bg-danger">
                              Not Available
                            </span>
                          )}
                        </p>
                      </Col>
                      <Col sm={6}>
                        <div>
                          <strong>Pincodes:</strong>
                          <ul className="list-unstyled">
                            {/* Show limited pincodes or all based on showMorePincodes state */}
                            {(showMorePincodes[pincodeData.pincodeId]
                              ? pincodeData.pincode
                              : pincodeData.pincode.slice(0, PINCODES_TO_SHOW)
                            ).map((code, i) => (
                              <li key={i}>
                                <i className="uil uil-tag-alt"></i> {code}
                              </li>
                            ))}
                          </ul>
                          {pincodeData.pincode.length > PINCODES_TO_SHOW && (
                            <Button
                              variant="link"
                              className="p-0"
                              onClick={() =>
                                handleShowMorePincodes(pincodeData.pincodeId)
                              }
                            >
                              {showMorePincodes[pincodeData.pincodeId]
                                ? "Show Less"
                                : "Show More"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          ) : (
            <p>No pincodes available</p>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default ListPincode;
