import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Input, Space } from 'antd';
import PageTitle from '../../../components/PageTitle';

function CustomerDetails() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_FOS_CLIENT_API}/user?tenantName=${localStorage.getItem('TenantId')}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setUsers(data);
        setFilteredUsers(data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
  
    fetchData();
  }, []);
  

  const handleSearch = (value) => {
    setSearchText(value);
    const lowercasedValue = value.toLowerCase();
    const filtered = users.filter(user =>
      user.email.toLowerCase().includes(lowercasedValue) ||
      user.phone.includes(lowercasedValue)
    );
    setFilteredUsers(filtered);
  };

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Phone',
      key: 'phone',
      render: (text, record) => record.googleId ? 'Google login' : record.phone,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Address',
      key: 'address',
      render: (text, record) => record.address === "" ? 'Google login' : record.address,
    },
  ];

  return (
    <div>
            <PageTitle
        breadCrumbItems={[
          { label: "app", path: "/apps/Orders" },
          { label: "Customers", path: "/apps/Orders", active: true },
        ]}
        title={"Customer List"}
      />
      <Space style={{ marginBottom: 16 }}>
        <Input.Search
          placeholder="Search by email or phone"
          enterButton
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Space>
      <Table
        dataSource={filteredUsers}
        columns={columns}
        className='rounded'
        rowKey="userId"
      />
    </div>
  );
}

export default CustomerDetails;