import React from 'react';

function Design() {
  return (
    <div className='d-none d-lg-block'>
      {' '}
      <div
        style={{
          width: '30%',
          left: '10%',
          top: '30%',
          height: '400px',
          backdropFilter: 'blur(10px) saturate(360%)',
          background: 'rgba(0,0,0,0.1)',
          borderRadius: '20px',
        }}
        className="position-absolute text-white shadow p-5"
      >
  
        <h2  className='text-white'>Create a Store without limits</h2>

        <div style={{color:'silver'}} className='mt-4'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates voluptatibus a ratione? Libero illum perspiciatis nulla porro iste dolore voluptate, soluta expedita laudantium perferendis tempore, rerum eaque animi eveniet corrupti?Lorem ipsum dolor sit. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolores, officia.</div>
      </div>
    </div>
  );
}

export default Design;
