import React from "react";
import ReactDOM from "react-dom/client";
import "./i18n";
import App from "./App";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'resize-observer-polyfill'; 
// Suppress the ResizeObserver loop limit exceeded error
const resizeObserverLoopErr = /(ResizeObserver loop limit exceeded)/;

window.addEventListener('error', (e) => {
  if (resizeObserverLoopErr.test(e.message)) {
    e.stopImmediatePropagation();
  }
});

const observer = new ResizeObserver((entries) => {
  entries.forEach(entry => {
    console.log('Resized:', entry.target);
    console.log('Content Rect:', entry.contentRect);
  });
});


const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <Provider store={configureStore({})}>
    <React.Fragment>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
        <ToastContainer
          position="top-right" 
          autoClose={2000} 
          hideProgressBar={false} 
          newestOnTop={false} 
          closeOnClick 
          rtl={false} 
          pauseOnHover 
          draggable 
          pauseOnFocusLoss 
          theme="light" 
        />
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);
