import React, { useEffect, useState } from "react";

import AllRoutes from "./routes/Routes";

import { configureFakeBackend } from "./helpers";

// Themes
// For Default import Theme.scss
import "./assets/scss/Theme.scss";
import LandingPage from "./pages/landing/LandingPage";

// For Dark import Theme-Dark.scss
// import './assets/scss/Theme-Dark.scss';

// configure fake backend
configureFakeBackend();


const App = () => {

  const handleLandingPage = () =>{
    sessionStorage.setItem('landingPage', 'false');
    window.location.reload();
  }

  console.log(sessionStorage.getItem('landingPage'));
  
  
  return (
    <>
      {
        (sessionStorage.getItem('landingPage')==null)
        ?
        <LandingPage handleLandingPage={handleLandingPage}/>
        :
        <React.Fragment>
          <AllRoutes />
        </React.Fragment>  
      }
    </>
  );
};

export default App;