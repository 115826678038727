import React from "react";
import { Link } from "react-router-dom";
import img from "./loding.png";
import "./Landing.css";
import NavLand from "./NavLand";
import ConBody from "./ConBody";
import Pricing from "../other/Pricing";
function LandingPage({ handleLandingPage }) {
  return (
    <div>
      <ConBody handleLandingPage={handleLandingPage} />
      <div className="">
        <div className="card-container">
          <div className="card">
            <img src={img} alt="Profile 1" className="card-image" />
          </div>
          <div className="main-content">
            <h1>Create your design</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
              in libero risus semper habitant arcu eget.
            </p>

            <button onClick={handleLandingPage} className="explore-button">
              Start Now
            </button>
          </div>
        </div>
        <Pricing />
        <section className="features-section mt-4">
          <h1>The best design for your studio website</h1>
          <div className="feature-cards">
            <div className="feature-card">
              <h3>Audit</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing ac aliquam ac
                volutpat, viverra magna risus aliquam massa.
              </p>
              <Link to="/" className="explore-link">
                Experienced team
              </Link>
            </div>
            <div className="feature-card">
              <h3>Teamwork</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing ac aliquam ac
                volutpat, viverra magna risus aliquam massa.
              </p>
              <Link to="/" className="explore-link">
                Experienced team
              </Link>
            </div>
            <div className="feature-card">
              <h3>Create</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing ac aliquam ac
                volutpat, viverra magna risus aliquam massa.
              </p>
              <Link to="/" className="explore-link">
                Experienced team
              </Link>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default LandingPage;
