import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, Typography } from "antd";
import { changeSidebarTheme, changeTopbarTheme } from "../../../redux/actions";
import * as layoutConstants from "../../../constants/layout";
import './style.css';

const { Title } = Typography;

const ThemeCustomizer = () => {
  const dispatch = useDispatch();

  const { topbarTheme, leftSideBarTheme } = useSelector((state) => ({
    topbarTheme: state.Layout.topbarTheme,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
  }));

  const changeTopBarTheme = (theme) => {
    dispatch(changeTopbarTheme(theme));
  };

  const changeLeftSidebarTheme = (theme) => {
    dispatch(changeSidebarTheme(theme));
  };

  return (
    <div className="theme-customizer">
      <Card title="Theme Customizer" bordered={false} style={{ width: 400 }}>
        <Title level={4}>Choose Top Bar Theme</Title>
        <Button
          type={topbarTheme === layoutConstants.TopbarTheme.TOPBAR_THEME_LIGHT ? "primary" : "default"}
          onClick={() =>
            changeTopBarTheme(layoutConstants.TopbarTheme.TOPBAR_THEME_LIGHT)
          }
        >
          Light Theme (Topbar)
        </Button>
        <Button
          type={topbarTheme === layoutConstants.TopbarTheme.TOPBAR_THEME_DARK ? "primary" : "default"}
          onClick={() =>
            changeTopBarTheme(layoutConstants.TopbarTheme.TOPBAR_THEME_DARK)
          }
          style={{ marginLeft: "10px" }}
        >
          Dark Theme (Topbar)
        </Button>

        <Title level={4} style={{ marginTop: "20px" }}>Choose Sidebar Theme</Title>
        <Button
          type={leftSideBarTheme === layoutConstants.SideBarTheme.LEFT_SIDEBAR_THEME_LIGHT ? "primary" : "default"}
          onClick={() =>
            changeLeftSidebarTheme(layoutConstants.SideBarTheme.LEFT_SIDEBAR_THEME_LIGHT)
          }
        >
          Light Theme (Sidebar)
        </Button>
        <Button
          type={leftSideBarTheme === layoutConstants.SideBarTheme.LEFT_SIDEBAR_THEME_DARK ? "primary" : "default"}
          onClick={() =>
            changeLeftSidebarTheme(layoutConstants.SideBarTheme.LEFT_SIDEBAR_THEME_DARK)
          }
          style={{ marginLeft: "10px" }}
        >
          Dark Theme (Sidebar)
        </Button>
      </Card>
    </div>
  );
};

export default ThemeCustomizer;
