// src/components/InvoicePrintComponent.jsx

import React from 'react';
import './InvoicePrintComponent.css'; // Add custom styles for the invoice

const InvoicePrintComponent = React.forwardRef(({ order }, ref) => {
  if (!order) return null;

  return (
    <div ref={ref} className="invoice-print-container">
      <div className="invoice-header">
        <h1>Invoice</h1>
        <p><strong>Order ID:</strong> {order.orderId}</p>
      </div>
      <div className="invoice-body">
        <h2>Customer Details</h2>
        <p><strong>Name:</strong> {order.User.name}</p>
        <p><strong>Email:</strong> {order.User.email}</p>
        <p><strong>Phone:</strong> {order.ShippingAddress.Phone}</p>
        <p><strong>Address:</strong> {order.ShippingAddress.address}</p>
        <h2>Order Details</h2>
        <p><strong>Total Amount:</strong> ${order.totalAmount}</p>
        <p><strong>Payment Method:</strong> {order.paymentmtd}</p>
      </div>
    </div>
  );
});

export default InvoicePrintComponent;
