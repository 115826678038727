import React from 'react'
import PageTitle from '../../../components/PageTitle'

function FeedBack() {
  return (
    <div>
           <PageTitle
        breadCrumbItems={[
          { label: "app", path: "/apps/Feedback" },
          { label: "Feedback", path: "/apps/Feedback", active: true },
        ]}
        title={"Feedback"}
      />
    </div>
  )
}

export default FeedBack