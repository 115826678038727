import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceSmileBeam, faFaceSadTear } from "@fortawesome/free-regular-svg-icons";
import { Spinner } from "react-bootstrap"; // Import Spinner for loading indicator
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported

function DomainCheck() {
  const [domainName, setDomainName] = useState('');
  const [domainStatusDisplay, setDomainStatusDisplay] = useState(false);
  const [domainAvailability, setDomainAvailability] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state

  function handleCheck(e) {
    e.preventDefault();
    if (domainName.trim() === '') return; // Prevent checking for empty domain name
    setLoading(true); // Start loading

    // Use the fetch API for the request
    fetch(`${process.env.REACT_APP_FOS_CLIENT_API}/check-domain?domainName=${domainName}`)
      .then(response => response.json())
      .then(data => {
        console.log('Full response:', data); // Log the complete response to debug

        // Safely access the properties from the response
        const available = data?.available;
        setDomainAvailability(available);
        setDomainStatusDisplay(true);
        setLoading(false); // Stop loading
        console.log(`Domain Availability:\nAvailable: ${available}\nDomain: ${data?.domain}\nPrice: $${data?.price}`);
      })
      .catch(err => {
        console.error('Error checking domain:', err);
        setDomainStatusDisplay(true); // Display the status even if there's an error
        setLoading(false); // Stop loading even if there's an error
      });
  }

  return (
    <div className="container mt-5" style={{ maxWidth: '600px' }}>
      <h2 className="text-center mb-4">Check Domain Availability</h2>
      <form style={{ display: 'flex', flexDirection: 'column', gap: '15px' }} onSubmit={handleCheck}>
        <input
          type="text"
          value={domainName}
          onChange={(e) => setDomainName(e.target.value)}
          placeholder="Enter domain name"
          required
          className="form-control"
          style={{ height: '40px' }}
        />
        <button
          className="btn btn-primary"
          type="submit"
          disabled={loading}
        >
          {loading ? <Spinner as="span" animation="border" size="sm" /> : 'Check'}
        </button>
      </form>
      {domainStatusDisplay && (
        <div className="mt-3">
          {domainAvailability ? (
            <p className="text-success text-center">
              <FontAwesomeIcon icon={faFaceSmileBeam} /> <b>"{domainName}"</b> is available!
            </p>
          ) : (
            <p className="text-danger text-center">
              <FontAwesomeIcon icon={faFaceSadTear} /> <b>"{domainName}"</b> is not available.
            </p>
          )}
        </div>
      )}
    </div>
  );
}

export default DomainCheck;
